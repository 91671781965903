import React from "react";
import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";
import { datastore } from "../datastore";

const ProtectedRoutes = () => {
  // Validata Token
  var isExpired = true;
  const token = localStorage.getItem("token");
  if (token && token !== "") {

    datastore.data.customerId = localStorage.getItem("userid") + "";
    datastore.data.name = localStorage.getItem("username") + "";
    datastore.data.token = localStorage.getItem("token") + "";

    var decodedToken = jwtDecode(token);

    var dateNow = new Date();
    if (decodedToken.exp) {
      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        console.log("Token expired.");
        localStorage.removeItem("token");
      } else {
        console.log("Valid token");
        isExpired = false;
      }
    }
  }

  return isExpired ? <Navigate to="/login" replace /> : <Outlet />;
};

export default ProtectedRoutes;
