import React, { FC } from "react";
import './TopMenu.css'
import { useNavigate } from "react-router-dom";

export enum TopMenuItem {
  NewOrder,
  OrderList,
  Settings,
  None
}


export enum viewType {
  Auther,
  EstateAgent,
  MediaProcessor
}

export interface TopMenuProps {
  selectedItem: TopMenuItem
  view: viewType
}

const TopMenu: FC<TopMenuProps> = (props) => {

  const navigate = useNavigate();
  const handleItemClicked = (item: TopMenuItem) => {
    switch (item) {
      case TopMenuItem.NewOrder:
        navigate('/neworder');
        break;
      case TopMenuItem.OrderList:
        navigate('/orders');
        break;
      case TopMenuItem.Settings:
        navigate('/profiles');
        break;

      default:
        break;
    }

  }

  return (

    <div className="MainMenu">
      {props.view === viewType.EstateAgent && (
        <>
          <input id="newOrder" type="radio" readOnly name="css-tabs" checked={props.selectedItem === TopMenuItem.NewOrder ? true : false} onClick={() => handleItemClicked(TopMenuItem.NewOrder)} />
          <input id="orderList" type="radio" readOnly name="css-tabs" checked={props.selectedItem === TopMenuItem.OrderList ? true : false} onClick={() => handleItemClicked(TopMenuItem.OrderList)} />
          <input id="settings" type="radio" readOnly name="css-tabs" checked={props.selectedItem === TopMenuItem.Settings ? true : false} onClick={() => handleItemClicked(TopMenuItem.Settings)} />

          <div id="tabs">
            <label id="tab1" htmlFor="newOrder">
              Ny tekst
            </label>
            <label id="tab2" htmlFor="orderList">
              Genereringer
            </label>
            <label id="tab3" htmlFor="settings">
              Tekst profiler
            </label>
          </div>
        </>
      )}
      {props.view === viewType.Auther && (
        <>
         <input id="newOrder" type="radio" checked name="css-tabs" />
          <div id="tabs">
            <label id="tab1" htmlFor="newOrder">
              BESTILLING
            </label>

          </div>
        </>
      )}
    </div>
  );
};

export default TopMenu;
