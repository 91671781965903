import React, { FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useQuery, gql, useMutation } from "@apollo/client";
import { FormControl, TextField } from "@mui/material";
import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from "moment";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const GET_PROFILE = gql`
  query getTextProfile($id: ID!) {
    getTextProfile(id: $id) {
      sections {
        id
        name
        externalRef
        textType
        textLength
        textLengthUnit
        sorting
        createdAt
        updatedAt
        bulletCount
      }
    }
  }
`;

const CREATE_TEXT_SECTION = gql`
  mutation createTextProfileSection($input: CreateTextProfileSectionInput!) {
    createTextProfileSection(input: $input) {
      id
    }
  }
`;

const EDIT_TEXT_SECTION = gql`
  mutation editTextProfileSection($input: EditTextProfileSectionInput!) {
    editTextProfileSection(input: $input) {
      id
    }
  }
`;

const DELETE_TEXT_SECTION = gql`
  mutation deleteTextProfileSection($id: ID!) {
    deleteTextProfileSection(id: $id)
  }
`;


export interface TextSectionProps {
  textProfileId: string;
}

const TextSection: FC<TextSectionProps> = (props) => {
  const [newSection, setSection] = React.useState({ id: "", name: "", externalRef: "", textType: "TEXT", bulletCount: 5, textLength: 200, textLengthUnit: "CHARACTERS", textWritingProfileId: props.textProfileId });

  const [selectedSectionId, setSelectedSectionId] = React.useState("");
  const [openDeleteSection, setOpenDeleteSection] = React.useState(false);
  const [openCreateNewSection, setOpenCreateNewSection] = React.useState(false);
  const [openEditSection, setOpenEditSection] = React.useState(false);

  const [CreateTextSection] = useMutation(CREATE_TEXT_SECTION);
  const [DeleteTextSection] = useMutation(DELETE_TEXT_SECTION);
  const [EditTextSection] = useMutation(EDIT_TEXT_SECTION);
  const { loading, error, data, refetch } = useQuery(GET_PROFILE, {
    variables: {
      id: props.textProfileId
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }

  const handleSectionRowClicked = (row: any) => {
    console.log('Row:', row);
    setSection({ id: row.row.id, name: row.row.name, externalRef: row.row.externalRef, textType: row.row.textType, bulletCount: row.row.bulletCount, textLength: row.row.textLength, textLengthUnit: row.row.textLengthUnit, textWritingProfileId: props.textProfileId });
    setOpenEditSection(true);
  }

  const handleCreateNewSectionClickOpen = () => {
    setSection({ id: "", name: "", externalRef: "", textType: "TEXT", bulletCount: 5, textLength: 200, textLengthUnit: "CHARACTERS", textWritingProfileId: props.textProfileId });
    setOpenCreateNewSection(true);
  };

  const handleCreateNewSectionClickClose = () => {
    setOpenCreateNewSection(false);
  };

  const handleEditSectionClickClose = () => {
    setOpenEditSection(false);
  };

  const onSectionDeleteButtonClick = (e: any, row: any) => {
    e.stopPropagation();
    setSelectedSectionId(row.id);
    setOpenDeleteSection(true);
  };

  const handleDeleteSectionClickClose = () => {
    setOpenDeleteSection(false);
  };

  const handleCreateNewSectionClicked = () => {
    const { id, ...newSectionWithoutId } = newSection;
    CreateTextSection({
      variables: {
        input: newSectionWithoutId
      }
    }).then(() => {
      handleCreateNewSectionClickClose();
      refetch()
    });
  }

  const handleEditSectionClicked = () => {

    const { textWritingProfileId, ...newSectionWithoutProfileId } = newSection;


    EditTextSection({
      variables: {
        input: newSectionWithoutProfileId
      }
    }).then(() => {
      handleEditSectionClickClose();
      refetch()
    });
  }

  const handleDeleteSectionClick = () => {
    DeleteTextSection({
      variables: {
        id: selectedSectionId
      }
    }).then(() => {
      setOpenDeleteSection(false);
      refetch()
    });
  };

  const setSectionField = (field: string, value: any) => {
    if (field === 'bulletCount') {

      value = parseInt(value);
      if (value < 0) {
        value = 0;
      }
      if (value > 10) {
        value = 10;
      }
    }

    if (field === 'textLength') {
      value = parseInt(value);
      if (value < 0) {
        value = 0;
      }
      if (value > 2000) {
        value = 2000;
      }
    }
    setSection({ ...newSection, [field]: value });
  }

  const sectionColumns: GridColDef[] = [
    { field: 'name', headerName: 'Navn', flex: 1, filterable: false },
    { field: 'typeText', headerName: 'Type', flex: 1, filterable: false },
    { field: 'lengthText', headerName: 'Længde', flex: 1, filterable: false },
    { field: 'created', headerName: 'Oprettet', width: 120, align: 'left' },
    {
      field: 'actions', headerName: 'Slet', width: 50, align: 'left', renderCell: (params) => {
        return (
          <DeleteOutlinedIcon
            onClick={(e) => onSectionDeleteButtonClick(e, params.row)}
          />
        );
      }
    }
  ];

  const sectionRows = data.getTextProfile.sections.map((section: any) => {

    let type = "";
    switch (section.textType) {
      case "TEXT":
        type = "Tekst";
        break;
      case "HEADING":
        type = "Overskrift";
        break;
      case "SOME":
        type = "Tekst til SoMe";
        break;
      case "SALES_BULLETS":
        type = "Salgsbullets";
        break;
    }

    let textLength = section.textLength;
    if (section.textLengthUnit === "WORDS") {
      textLength = textLength + " ord";
    } else {
      textLength = textLength + " karakterer";
    }

    return {
      id: section.id,
      name: section.name,
      externalRef: section.externalRef,
      textType: section.textType,
      textLength: section.textLength,
      textLengthUnit: section.textLengthUnit,
      sorting: section.sorting,
      created: moment(section.createdAt).format("DD-MM-YYYY"),
      typeText: type,
      lengthText: textLength,
      bulletCount: section.bulletCount
    }
  });

  console.log('newSection:', newSection);

  return (
    <div className="mainTextSectionList">
      <Grid container spacing={0}>
        <Grid xs={12} style={{ width: '100%', borderWidth: '0px', textAlign: 'end' }}>
          <Button variant="outlined" color="primary" onClick={() => handleCreateNewSectionClickOpen()}>Ny tekst</Button>
        </Grid>
        <Grid xs={12} style={{ width: '100%', borderWidth: '0px' }}>
          <DataGrid

            style={{ borderWidth: '0px' }}
            onRowClick={handleSectionRowClicked}
            rows={sectionRows}
            columns={sectionColumns}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [''],
                },
              },
            }}
            autoHeight={true}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector

            sx={{
              // disable cell selection style
              '.MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              // pointer cursor on ALL rows
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        open={openDeleteSection}
        onClose={handleDeleteSectionClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Slet tekst?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du er ved at slette en tekst sektion. Er du sikker?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSectionClickClose}>Fortryd</Button>
          <Button onClick={handleDeleteSectionClick} autoFocus>
            Slet
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={openCreateNewSection}
        onClose={handleCreateNewSectionClickClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            handleCreateNewSectionClickClose();
          },
        }}
      >
        <DialogTitle>Opret ny tekst sektion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du er ved at oprette en ny tekst sektion.<br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Navn"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            defaultValue={newSection.name}
            onChange={(event) => setSectionField('name', event.target.value)}
          />

          <TextField

            size="small"
            margin="dense"
            id="externalRef"
            name="externalRef"
            label="Ekstern reference"
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={newSection.externalRef}
            onChange={(event) => setSectionField('externalRef', event.target.value)}
          />

          <FormControl fullWidth={true} style={{ marginTop: '8px', marginBottom: '4px' }}>
            <InputLabel htmlFor="outlined-adornment">Tekst type</InputLabel>
            <Select
              size="small"
              label="Tekst type"
              id="textType"
              fullWidth
              value={newSection.textType}
              onChange={(event) => setSectionField('textType', event.target.value)}
            >
              <MenuItem value={'TEXT'}>Tekst</MenuItem>
              <MenuItem value={'HEADING'}>Overskrift</MenuItem>
              <MenuItem value={'SOME'}>Tekst til SoMe</MenuItem>
              <MenuItem value={'SALES_BULLETS'}>Salgsbullets</MenuItem>
            </Select>
          </FormControl>
          {newSection.textType === "SALES_BULLETS" ? (
            <Grid container spacing={0}>
              <Grid xs={3}>
                <TextField
                  required
                  size="small"
                  margin="dense"
                  id="bulletCount"
                  name="bulletCount"
                  label="Antal bullets"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  defaultValue={newSection.bulletCount}
                  variant="outlined"
                  onChange={(event) => setSectionField('bulletCount', event.target.value)}
                />
              </Grid>
              <Grid xs={5}  pl={'10px'}>
                <TextField
                  required
                  size="small"
                  margin="dense"
                  id="textLength"
                  name="textLength"
                  label="Maksimal længde"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 2000 } }}
                  fullWidth
                  defaultValue={newSection.textLength}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLength', event.target.value)}
                />
              </Grid>

              <Grid xs={4} pl={'10px'}>
                <Select
                  style={{ marginTop: '8px', marginBottom: '4px' }}
                  size="small"

                  id="textLengthUnit"
                  fullWidth
                  value={newSection.textLengthUnit}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLengthUnit', event.target.value)}
                >
                  <MenuItem value={'WORDS'}>Ord</MenuItem>
                  <MenuItem value={'CHARACTERS'}>Karakterer</MenuItem>
                </Select>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={0}>
              <Grid xs={0} display={'none'}>
                <TextField
                  size="small"
                  margin="dense"
                  id="bulletCount"
                  name="bulletCount"
                  label="Antal bullets"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  defaultValue={newSection.bulletCount}
                  variant="outlined"
                  onChange={(event) => setSectionField('bulletCount', event.target.value)}
                />
              </Grid>
              <Grid xs={8}>
                <TextField
                  required
                  size="small"
                  margin="dense"
                  id="textLength"
                  name="textLength"
                  label="Maksimal længde"
                  type="number"
                  fullWidth
                  defaultValue={newSection.textLength}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLength', event.target.value)}
                />
              </Grid>

              <Grid xs={4} pl={'10px'}>
                <Select
                  style={{ marginTop: '8px', marginBottom: '4px' }}
                  size="small"

                  id="textLengthUnit"
                  fullWidth
                  value={newSection.textLengthUnit}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLengthUnit', event.target.value)}
                >
                  <MenuItem value={'WORDS'}>Ord</MenuItem>
                  <MenuItem value={'CHARACTERS'}>Karakterer</MenuItem>
                </Select>
              </Grid>
            </Grid>

          )
          }


        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateNewSectionClickClose}>Annuller</Button>
          <Button onClick={handleCreateNewSectionClicked} type="submit">Opret</Button>
        </DialogActions>
      </Dialog>



      <Dialog
        fullWidth
        open={openEditSection}
        onClose={handleEditSectionClickClose}
      >
        <DialogTitle>Redigere tekst sektion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du er ved at redigere en tekst sektion.<br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Navn"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            defaultValue={newSection.name}
            onChange={(event) => setSectionField('name', event.target.value)}
          />

          <TextField

            size="small"
            margin="dense"
            id="externalRef"
            name="externalRef"
            label="Ekstern reference"
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={newSection.externalRef}
            onChange={(event) => setSectionField('externalRef', event.target.value)}
          />

          <FormControl fullWidth={true} style={{ marginTop: '8px', marginBottom: '4px' }}>
            <InputLabel htmlFor="outlined-adornment">Tekst type</InputLabel>
            <Select
              size="small"
              label="Tekst type"
              id="textType"
              fullWidth
              value={newSection.textType}
              onChange={(event) => setSectionField('textType', event.target.value)}
            >
              <MenuItem value={'TEXT'}>Tekst</MenuItem>
              <MenuItem value={'HEADING'}>Overskrift</MenuItem>
              <MenuItem value={'SOME'}>Tekst til SoMe</MenuItem>
              <MenuItem value={'SALES_BULLETS'}>Salgsbullets</MenuItem>
            </Select>
          </FormControl>
          {newSection.textType === "SALES_BULLETS" ? (
            <Grid container spacing={0}>
              <Grid xs={3}>
                <TextField
                  required
                  size="small"
                  margin="dense"
                  id="bulletCount"
                  name="bulletCount"
                  label="Antal bullets"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  defaultValue={newSection.bulletCount}
                  variant="outlined"
                  onChange={(event) => setSectionField('bulletCount', event.target.value)}
                />
              </Grid>
              <Grid xs={5} pl={'10px'}>
                <TextField
                  required
                  size="small"
                  margin="dense"
                  id="textLength"
                  name="textLength"
                  label="Maksimal længde pr. bullet"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 2000 } }}
                  defaultValue={newSection.textLength}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLength', event.target.value)}
                />
              </Grid>

              <Grid xs={4} pl={'10px'}>
                <Select
                  style={{ marginTop: '8px', marginBottom: '4px' }}
                  size="small"

                  id="textLengthUnit"
                  fullWidth
                  value={newSection.textLengthUnit}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLengthUnit', event.target.value)}
                >
                  <MenuItem value={'WORDS'}>Ord</MenuItem>
                  <MenuItem value={'CHARACTERS'}>Karakterer</MenuItem>
                </Select>
              </Grid>
            </Grid>
          ) : (

            <Grid container spacing={0}>
              <Grid xs={0} display={'none'}>
                <TextField
                  size="small"
                  margin="dense"
                  id="bulletCount"
                  name="bulletCount"
                  label="Antal bullets"
                  type="hidden"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  defaultValue={newSection.bulletCount}
                  variant="outlined"
                  onChange={(event) => setSectionField('bulletCount', event.target.value)}
                />
              </Grid>
              <Grid xs={8}>
                <TextField
                  required
                  size="small"
                  margin="dense"
                  id="textLength"
                  name="textLength"
                  label="Maksimal længde pr. bullet"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 2000 } }}
                  defaultValue={newSection.textLength}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLength', event.target.value)}
                />
              </Grid>

              <Grid xs={4} pl={'10px'}>
                <Select
                  style={{ marginTop: '8px', marginBottom: '4px' }}
                  size="small"

                  id="textLengthUnit"
                  fullWidth
                  value={newSection.textLengthUnit}
                  variant="outlined"
                  onChange={(event) => setSectionField('textLengthUnit', event.target.value)}
                >
                  <MenuItem value={'WORDS'}>Ord</MenuItem>
                  <MenuItem value={'CHARACTERS'}>Karakterer</MenuItem>
                </Select>
              </Grid>
            </Grid>

          )}



        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSectionClickClose}>Annuller</Button>
          <Button onClick={handleEditSectionClicked} type="submit">Gem</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextSection;
