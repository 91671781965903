import React from "react";

//import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderList.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import moment from "moment";

const GET_ORDERS = gql`
  query getTextGenerationOrders {
    getTextGenerationOrders {
      id
      address
      profile {
        id
        name
      }
      createdAt
    }
  }
`;

const OrderList: React.FC = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ORDERS, {
    variables: {
      customerId: datastore.data.customerId
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    console.log("Error", error);
    //return <Error errorCode={500} />;
  }

  if (!data) {
    console.log("Error, no data");
    //return (<Error errorCode={500} />);
  }

  const columns: GridColDef[] = [
    { field: 'address', headerName: 'Adresse', flex: 1, filterable: false },
    { field: 'profile', headerName: 'Profil', flex: 1, filterable: false },
    { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left' },
  ];


  const rows = data.getTextGenerationOrders.map((order: any) => {
    return {
      id: order.id,
      address: order.address,
      profile: order.profile ? order.profile.name : '',
      createdAt: moment(order.createdAt).format("DD-MM-YYYY")
    }
  });

  const handleRowClicked = (row: any) => {
    navigate('/order/' + row.id);
    //window.location.href = '/order/' + row.id;
  }

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Genereringer</div>
            <Grid container spacing={0}>
              <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                <DataGrid
                  style={{ borderWidth: '0px' }}
                  onRowClick={handleRowClicked}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [''],
                      },
                    },
                  }}
                  autoHeight={true}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderList;
