import React, { FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useQuery, gql, useMutation } from "@apollo/client";
import { FormControl,  TextField } from "@mui/material";
import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from "moment";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const GET_PROFILE = gql`
  query getTextProfile($id: ID!) {
    getTextProfile(id: $id) {
      instructions {
        id
        instruction
        instructionType
        createdAt
        updatedAt
      }
    }
  }
`;

const CREATE_TEXT_INSTRUCTION = gql`
  mutation createTextProfileInstruction($input: CreateTextProfileInstructionInput!) {
    createTextProfileInstruction(input: $input) {
      id
    }
  }
`;

const EDIT_TEXT_INSTRUCTION = gql`
  mutation editTextProfileInstruction($input: EditTextProfileInstructionInput!) {
    editTextProfileInstruction(input: $input) {
      id
    }
  }
`;

const DELETE_TEXT_INSTRUCTION = gql`
  mutation deleteTextProfileInstruction($id: ID!) {
    deleteTextProfileInstruction(id: $id)
  }
`;


export interface TextInstructionProps {
  textProfileId: string;
}

const TextInstructions: FC<TextInstructionProps> = (props) => {
  const [newInstruction, setInstruction] = React.useState({ id:"", instruction: "", instructionType: "TEXT", textWritingProfileId: props.textProfileId });

  const [selectedInstructionId, setSelectedInstructionId] = React.useState("");
  const [openDeleteInstruction, setOpenDeleteInstruction] = React.useState(false);
  const [openCreateNewInstruction, setOpenCreateNewInstruction] = React.useState(false);
  const [openEditInstruction, setOpenEditInstruction] = React.useState(false);

  const [CreateTextInstruction] = useMutation(CREATE_TEXT_INSTRUCTION);
  const [DeleteTextInstruction] = useMutation(DELETE_TEXT_INSTRUCTION);
  const [EditTextInstruction] = useMutation(EDIT_TEXT_INSTRUCTION);
  const { loading, error, data, refetch } = useQuery(GET_PROFILE, {
    variables: {
      id: props.textProfileId
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }

  const handleInstructionRowClicked = (row: any) => {
    console.log('Row:', row);
    setInstruction({ id: row.row.id, instruction: row.row.instruction, instructionType: row.row.instructionType, textWritingProfileId: props.textProfileId });
    setOpenEditInstruction(true);
  }

  const handleCreateNewInstructionClickOpen = () => {
    setInstruction({ id:"", instruction: "", instructionType: "TEXT", textWritingProfileId: props.textProfileId });
    setOpenCreateNewInstruction(true);
  };

  const handleCreateNewInstructionClickClose = () => {
    setOpenCreateNewInstruction(false);
  };

  const handleEditInstructionClickClose = () => {
    setOpenEditInstruction(false);
  };

  const onInstructionDeleteButtonClick = (e: any, row: any) => {
    e.stopPropagation();
    setSelectedInstructionId(row.id);
    setOpenDeleteInstruction(true);
  };

  const handleDeleteInstructionClickClose = () => {
    setOpenDeleteInstruction(false);
  };

  const handleCreateNewInstructionClicked = () => {
    const { id, ...newInstructionWithoutId } = newInstruction;
    CreateTextInstruction({
      variables: {
        input: newInstructionWithoutId
      }
    }).then(() => {
      handleCreateNewInstructionClickClose();
      refetch()
    }); 
  }

  const handleEditInstructionClicked = () => {

    const { textWritingProfileId, ...newInstructionWithoutProfileId } = newInstruction;

    
    EditTextInstruction({
      variables: {
        input: newInstructionWithoutProfileId
      }
    }).then(() => {
      handleEditInstructionClickClose();
      refetch()
    }); 
  }

  const handleDeleteInstructionClick = () => {
    DeleteTextInstruction({
      variables: {
        id: selectedInstructionId
      }
    }).then(() => {
      setOpenDeleteInstruction(false);
      refetch()
    });
  };

  const setInstructionField = (field: string, value: any) => {
    if (field === 'textLength') {
      value = parseInt(value);
      if (value < 0) {
        value = 0;
      }
      if (value > 2000) {
        value = 2000;
      }
    }
    setInstruction({ ...newInstruction, [field]: value });
  }

  const instructionColumns: GridColDef[] = [
    
    { field: 'instructionShort', headerName: 'Instruks', flex: 1, filterable: false },
    { field: 'typeText', headerName: 'Type', width: 120, filterable: false  },
    { field: 'created', headerName: 'Oprettet', width: 120, align: 'left' },
    {
      field: 'actions', headerName: 'Slet', width: 50, align: 'left', renderCell: (params) => {
        return (
          <DeleteOutlinedIcon
            onClick={(e) => onInstructionDeleteButtonClick(e, params.row)}
          />
        );
      }
    }
  ];

  const instructionRows = data.getTextProfile.instructions.map((instruction: any) => {



    let type = "";
    switch (instruction.instructionType) {
      case "TEXT":
        type = "Tekst";
        break;
      case "HEADING":
        type = "Overskrift";
        break;
      case "SOME":
        type = "Tekst til SoMe";
        break;
    }

    return {
      id: instruction.id,
      instruction: instruction.instruction,
      instructionShort: instruction.instruction,
      created: moment(instruction.createdAt).format("DD-MM-YYYY"),
      instructionType: instruction.instructionType,
      typeText: type
    }
  });

  return (
    <div className="mainTextInstructionList">
      <Grid container spacing={0}>
        <Grid xs={12} style={{ width: '100%', borderWidth: '0px', textAlign: 'end' }}>
          <Button variant="outlined" color="primary" onClick={() => handleCreateNewInstructionClickOpen()}>Ny instruks</Button>
        </Grid>
        <Grid xs={12} style={{ width: '100%', borderWidth: '0px' }}>
          <DataGrid
          
            style={{ borderWidth: '0px' }}
            onRowClick={handleInstructionRowClicked}
            rows={instructionRows}
            columns={instructionColumns}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [''],
                },
              },
            }}
            autoHeight={true}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector

            sx={{
              // disable cell selection style
              '.MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              // pointer cursor on ALL rows
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        open={openDeleteInstruction}
        onClose={handleDeleteInstructionClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Slet tekst instruks?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du er ved at slette en tekst instruks. Er du sikker?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteInstructionClickClose}>Fortryd</Button>
          <Button onClick={handleDeleteInstructionClick} autoFocus>
            Slet
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={openCreateNewInstruction}
        onClose={handleCreateNewInstructionClickClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            handleCreateNewInstructionClickClose();
          },
        }}
      >
        <DialogTitle>Opret ny tekst instruks</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du er ved at oprette en ny tekst instruks.<br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="instruction"
            name="instruction"
            label="Instruks"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            defaultValue={newInstruction.instruction}
            onChange={(event) => setInstructionField('instruction', event.target.value)}
          />

          <FormControl fullWidth={true} style={{ marginTop: '8px', marginBottom: '4px' }}>
            <InputLabel htmlFor="outlined-adornment">Type</InputLabel>
            <Select
              size="small"
              label="Tekst type"
              id="instructionType"
              fullWidth
              value={newInstruction.instructionType}
              onChange={(event) => setInstructionField('instructionType', event.target.value)}
            >
              <MenuItem value={'TEXT'}>Tekst</MenuItem>
              <MenuItem value={'HEADING'}>Overskrift</MenuItem>
              <MenuItem value={'SOME'}>Tekst til SoMe</MenuItem>
            </Select>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateNewInstructionClickClose}>Annuller</Button>
          <Button onClick={handleCreateNewInstructionClicked} type="submit">Opret</Button>
        </DialogActions>
      </Dialog>



      <Dialog
        fullWidth
        open={openEditInstruction}
        onClose={handleEditInstructionClickClose}
      >
        <DialogTitle>Redigere tekst instruks</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du er ved at redigere en tekst instruks.<br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="instruction"
            name="instruction"
            label="Instruks"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            defaultValue={newInstruction.instruction}
            onChange={(event) => setInstructionField('instruction', event.target.value)}
          />

         

          <FormControl fullWidth={true} style={{ marginTop: '8px', marginBottom: '4px' }}>
            <InputLabel htmlFor="outlined-adornment">Type</InputLabel>
            <Select
              size="small"
              label="Tekst type"
              id="instructionType"
              fullWidth
              value={newInstruction.instructionType}
              onChange={(event) => setInstructionField('instructionType', event.target.value)}
            >
              <MenuItem value={'TEXT'}>Tekst</MenuItem>
              <MenuItem value={'HEADING'}>Overskrift</MenuItem>
              <MenuItem value={'SOME'}>Tekst til SoMe</MenuItem>
            </Select>
          </FormControl>

          

        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditInstructionClickClose}>Annuller</Button>
          <Button onClick={handleEditInstructionClicked} type="submit">Gem</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextInstructions;
