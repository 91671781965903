//import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // path to your i18n config
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as ReactDOM from "react-dom/client";
//import { datastore } from "./datastore";
import ProtectedRoutes from "./providers/protectedRoutes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import "./index.css";
import Login from "./pages/login/login";
import Error from "./pages/error/error";
import AiTextDemo from "./pages/aiTextDemo/aiTextDemo";
import OrderList from "./pages/orderList/orderList";
//import Settings from "./pages/settings/settings";
//import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import { setContext } from "@apollo/client/link/context";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import OrderInfo from "./pages/orderInfo/orderInfo";
import ProfilList from "./pages/profilList/profilList";
import ProfilInfo from "./pages/profilInfo/profilInfo";


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: "Bearer " + token,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),

});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const loginRouter = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login/:t",
    element: <Login />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/neworder",
        element: <AiTextDemo />,
      },
      {
        path: "/orders",
        element: <OrderList />,
      },
      {
        path: "/order/:id",
        element: <OrderInfo />,
      },
      {
        path: "/profiles",
        element: <ProfilList />,
      },
      {
        path: "/profile/:id",
        element: <ProfilInfo />,
      }
    ],
  },
  {
    path: "*",
    element: <Error errorCode={404} />,
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});

root.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={loginRouter} ></RouterProvider>
      </I18nextProvider>
      </ThemeProvider>
  </ApolloProvider>
);
