import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./aiTextDemo.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { datastore } from "../../datastore";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";

const GENERATE_TEXT = gql`
  mutation createTextGenerationOrder($input: TextGenerationOrderInput!) {
    createTextGenerationOrder(input: $input) {
      id
      headline
      normaltext
      someText
      error
      status
    }
  }
`;

const GET_TEXT = gql`
  query getTextGenerationOrder($id: ID!) {
    getTextGenerationOrder(id: $id) {
      id
      headline
      normaltext
      someText
      error
      status
    }
  }
`

const GET_TEXT_PROFILES = gql`
  query getTextProfiles($includeDefault: Boolean!) {
    getTextProfiles(includeDefault: $includeDefault) {
      id
      name
    }
  }
`

const CRAWL_WEBSITE = gql`
  mutation getWebsiteData($websiteUrl: String!) {
    getWebsiteData(websiteUrl: $websiteUrl) {
      address
      imageUrls
    }
  }
`;

const AiTextDemo: React.FC = () => {
  const navigate = useNavigate();
  const [CrawlWebsite, { error, loading: crawlWebsiteLoading, data: CrawlWebsiteData }] = useMutation(CRAWL_WEBSITE);
  const [GenerateText, { data: GenerateTextData }] = useMutation(GENERATE_TEXT);
  const [GetText, { error: GetTextError, data: GetTextData }] = useLazyQuery(GET_TEXT);


  const { data: dataProfiles } = useQuery(GET_TEXT_PROFILES, {
    variables: {
      includeDefault: true
    },
    fetchPolicy: 'network-only'
  });

  const productContainerRef = React.createRef<HTMLDivElement>();
  const [version, setVersion] = useState(0);
  const [intervalobj, setIntervalobj] = useState<any>(null);
  //const [mwCases, setmwCases] = useState<any>([]);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [selectedTextProfileId, setSelectedTextProfileId] = useState("");

  //const [buyingTermsAccepted, setBuyingTermsAccepted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  //const [subproducts, setSubproducts] = useState<Array<any>>([]);
  const steps = ["Vælg sag", "Sags info", "Generering af tekst"];


  if (error) {
    ///return <Error errorCode={500} />;
  }

  if (CrawlWebsiteData) {
    console.log("CrawlWebsiteData", CrawlWebsiteData);
    datastore.data.aiText.address = CrawlWebsiteData.getWebsiteData.address;

    //change the size of the images
    for (let i = 0; i < CrawlWebsiteData.getWebsiteData.imageUrls.length; i++) {
      CrawlWebsiteData.getWebsiteData.imageUrls[i] = CrawlWebsiteData.getWebsiteData.imageUrls[i].split("?")[0] + "?width=1200";
    }

    datastore.data.aiText.imageUrls = CrawlWebsiteData.getWebsiteData.imageUrls;
  }


  const handleNext = () => {
    let shouldValidate = false;
    //const orderData = datastore.data;

    if (activeStep === 0) {
      datastore.data.aiText.address = "";
      datastore.data.aiText.highlightText = "";
      datastore.data.aiText.imageUrls = [];

      if (datastore.data.aiText.websiteUrl === "") {
        shouldValidate = true;
      }
    }

    if (activeStep === 1) {

    }

    if (activeStep === 2) {

    }

    if (!shouldValidate) {
      setShouldValidate(false);

      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      if (activeStep + 1 === 1) {
        CrawlWebsite({
          variables: {
            websiteUrl: datastore.data.aiText.websiteUrl
          },
        })
      }
      if (activeStep + 1 === 2) {
        console.log("Generate text");
        GenerateText({
          variables: {
            input: {
              address: datastore.data.aiText.address,
              country: "DENMARK",
              imageUrls: datastore.data.aiText.imageUrls,
              highlightText: datastore.data.aiText.highlightText,
              callbackUrl: 'https://google.com',
              textProfileId: selectedTextProfileId
            }
          }
        }).catch((error) => {
          console.log('Error generating text', error)
        });
      }
    } else {
      setShouldValidate(true);
      if (shouldValidate) {
        setVersion(version + 1);
      }

      if (activeStep === 1) {
        if (productContainerRef.current) {
          productContainerRef.current.style.border = "1px solid red";
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleHighlightTextChange = async (event: any) => {

    datastore.data.aiText.highlightText = event.target.value;

  }

  const handleWebsiteUrlChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const elm = event.target as HTMLInputElement;
    datastore.data.aiText.websiteUrl = elm.value;
  }

  /*
  const Textarea = styled(
    () => `
    box-sizing: border-box;
    width: 100%;
    font-family: Roboto, 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 3px 3px 0 3px;
  `,
  );
  */

  //console.log("before render - datastore:", datastore.data);

  //console.log('GenerateTexeError:', GenerateTexeError?.graphQLErrors[0].message)
  //console.log('GenerateTextData:', GenerateTextData)


  if (GenerateTextData && intervalobj === null) {

    console.log('GenerateTextData:', GenerateTextData)

    const textID = GenerateTextData.createTextGenerationOrder.id;

    const tempIntervalObj = setInterval(() => {
      console.log('Checking if text is ready')
      GetText(
        {
          variables: {
            id: textID
          },
          fetchPolicy: "no-cache"
        })
    }, 2000)
    setIntervalobj(tempIntervalObj)
  }

  if ((intervalobj !== null && GetTextData && (GetTextData.getTextGenerationOrder.status === 'ERROR' || GetTextData.getTextGenerationOrder.status === 'DONE')) || (intervalobj && GetTextError)) {
    console.log('Clearing interval');
    clearInterval(intervalobj);
  }


  let errorText = ""
  if (GetTextData && GetTextData.getTextGenerationOrder.status === 'ERROR') {
    switch (GetTextData.getTextGenerationOrder.error) {
      case "Text not found":
        errorText = "Der er opstået en fejl, prøv igen"
        break;
      case "You need to provide at least 5 images":
        errorText = "Der er ikke nok billeder, til generering af teksten. Prøv en anden sag."
        break;
      case "The address is not valid":
        errorText = "Adressen er ikke valid, prøv en anden sag."
        break;
      case "Estate type is not supported":
        errorText = "Ejendomstypen er ikke understøttet, prøv en anden sag."
        break;
      case "You need to provide a floorplan image":
        errorText = "Der skal være mindst en plantegning, til generering af teksten. Prøv en anden sag."
        break;
      case "Error finding images for text":
        errorText = "Billederne kunne ikke behandles, prøv en anden sag."
        break;
      case "Error finding reference estates":
        errorText = "Der er opstået en fejl, prøv en anden sag."
        break;
      case "Error resizing images":
        errorText = "Billederne kunne ikke behandles, prøv en anden sag."
        break;
      default:
        errorText = "Der er opstået en fejl, prøv en anden sag."
        break;
    }
  }

  if (activeStep === 2 && GetTextData && GetTextData.getTextGenerationOrder.status === 'DONE') {

    navigate('/order/' + GetTextData.getTextGenerationOrder.id);
  }


  return (
    <div className="newOrder MainArea" key={version}>
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.NewOrder} view={viewType.EstateAgent} />
      </header>

      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Ny tekst</div>
            <Grid container spacing={0}>
              <Grid xs={2}>
                <div></div>
              </Grid>

              <Grid xs={8}>
                <div className="stepperContainer">
                  <Stepper activeStep={activeStep} className="stepper">
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};

                      return (
                        <Step key={label} {...stepProps}>

                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
              </Grid>
              <Grid xs={2}>
                <div></div>
              </Grid>
            </Grid>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Grid container spacing={4}>
                  <Grid xs={12} paddingTop={"100px"} paddingBottom={"180px"}>
                    <center>
                      <h2>
                        Tak for din bestilling.
                        <br />
                        Ordrer modtaget inden kl. 15 bliver kontaktet samme dag.
                      </h2>
                    </center>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="subheadingContainer">
                  <div className="line"></div>
                  <div className="subheading">{steps[activeStep]}</div>
                  <div className="line"></div>
                </div>

                {activeStep === 0 && (
                  <Grid container spacing={4}>
                    <Grid xs={2}>&nbsp;</Grid>
                    <Grid xs={8} textAlign={"center"}>
                      <p>
                        Find en sag på John fransens hjemmeside og indsæt url´en til sagen.
                      </p>
                    </Grid>
                    <Grid xs={2}>&nbsp;</Grid>
                    <Grid xs={2}>&nbsp;</Grid>
                    <Grid xs={8}>
                      <TextField
                        fullWidth
                        id="websiteUrl"
                        label="Url til ejendomspræsentation."
                        variant="standard"
                        defaultValue={''}
                        onChange={handleWebsiteUrlChange}
                        placeholder="eks. https://www.johnfrandsen.dk/sag/35940000860/vestergade-39-skanderborg-by-8660-skanderborg/"
                        error={
                          shouldValidate && (Boolean(datastore.data.aiText.websiteUrl === ""))
                        }
                      />
                    </Grid>
                    <Grid xs={2}>&nbsp;</Grid>
                  </Grid>
                )}

                {activeStep === 1 && (
                  <>
                    <Grid container spacing={4}>
                      <Grid xs={2}>&nbsp;</Grid>
                      <Grid xs={8} textAlign={"center"}>
                        {CrawlWebsiteData ? (
                          <>
                            {
                              CrawlWebsiteData.getWebsiteData.imageUrls.length > 0 ? (
                                <>
                                  <p>
                                    Fandt adressen: <b>{CrawlWebsiteData.getWebsiteData.address}</b> og <b>{CrawlWebsiteData.getWebsiteData.imageUrls.length}</b> billeder
                                  </p>
                                  <br />
                                  {dataProfiles.getTextProfiles.length > 0 && (
                                    <>
                                      <p>
                                        Vælg tekst profil?
                                      </p>
                                      <Select
                                        size="small"
                                        id="textProfile"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedTextProfileId}
                                        onChange={(e: any) => { setSelectedTextProfileId(e.target.value) }}
                                      >
                                        {dataProfiles.getTextProfiles.map((profile: any) => {
                                          return (
                                            <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
                                          )
                                        }
                                        )}
                                      </Select>
                                    </>
                                  )}
                                  <br />
                                  <br />
                                  <p>
                                    Er der noget du ønsker der skal ligges særlig vægt på?
                                  </p>
                                  <div className="itemData" style={{ width: '100%' }}>
                                    <TextField size="small" fullWidth multiline variant="outlined" placeholder="eks. 'Udsigten fra stuen ud over vandet' eller 'Total istandsat i 2024'" defaultValue={''} name="textSmall" onBlur={(e: any) => { handleHighlightTextChange(e) }} />
                                  </div>
                                  
                                </>
                              ) : (
                                <p>Der er opstået en fejl i en anden sag.</p>
                              )
                            }
                          </>
                        ) :
                          (
                            <div className="websiteCrawlerWorking">
                              <div className="loader"></div>
                              <p>Henter adresse og billeder fra website</p>
                            </div>
                          )}
                      </Grid>
                      <Grid xs={2}>&nbsp;</Grid>
                    </Grid>

                  </>
                )}

                {activeStep === 2 && (
                  <Grid container spacing={4}>
                    <Grid xs={2}>&nbsp;</Grid>
                    <Grid xs={8} textAlign={"center"}>
                      {errorText !== "" ? (
                        <p>{errorText}</p>
                      ) : (
                        <>
                          {(GetTextData && GetTextData.getTextGenerationOrder.status === 'DONE') ? (
                            <>
                              <p><b>OVERSKRIFT</b></p>
                              <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: GetTextData.getTextGenerationOrder.headline.replaceAll('\n', '<br/>') }}></p>
                              <br />
                              <p><b>NORMAL TEKST</b></p>
                              <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: GetTextData.getTextGenerationOrder.normaltext.replaceAll('\n', '<br/>') }}></p>
                              <br />
                              <p><b>SoMe TEKST</b></p>
                              <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: GetTextData.getTextGenerationOrder.someText.replaceAll('\n', '<br/>') }}></p>
                            </>
                          ) : (
                            <div className="websiteCrawlerWorking">
                              <div className="loader"></div>
                              <p>Genererer teksten...<br /><i>Tager ca. 1 minut</i></p>
                            </div>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid xs={2}>&nbsp;</Grid>
                  </Grid>
                )}

                {(!GetTextData && activeStep !== 2 && !crawlWebsiteLoading) && (
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 6, justifyContent: "flex-end" }} paddingTop={"40px"}>
                    {activeStep > 0 && (
                      <Button className="button" variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Tilbage
                      </Button>
                    )}

                    <Button className="button" variant="contained" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? "Færdig" : "Næste"}
                    </Button>
                  </Box>
                )}
              </React.Fragment>
            )}
          </div>

          <div className="photographyCompanyContactInfo">
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AiTextDemo;