import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./profilList.css";
import { datastore } from "../../datastore";
import { useQuery, gql, useMutation } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import moment from "moment";

const GET_TEXT_PROFILES = gql`
  query getTextProfiles($includeDefault: Boolean!) {
    getTextProfiles(includeDefault: $includeDefault) {
      id
      name
      language
      createdAt
    }
  }
`;

const DELETE_TEXT_PROFILE = gql`
  mutation deleteTextProfile($id: ID!) {
    deleteTextProfile(id: $id)
  }
`;

const CREATE_TEXT_PROFILE = gql`
  mutation createTextProfile($input: CreateTextProfileInput!) {
    createTextProfile(input: $input) {
      id
      name
      language
      createdAt
    }
  }
`;

const ProfilList: React.FC = () => {
  const [openCreateNewProfile, setOpenCreateNewProfile] = React.useState(false);
  const [openDeleteProfile, setDeleteProfile] = React.useState(false);
  const [newProfileName, setNewProfileName] = React.useState("");
  const [selectedProfileID, setSelectedProfileID] = React.useState("");
  const navigate = useNavigate();


  const [DeleteTextProfile] = useMutation(DELETE_TEXT_PROFILE);
  const [CreateTextProfile, { error: CreateTextProfileError, data: CreateTextProfileData }] = useMutation(CREATE_TEXT_PROFILE);
  const { loading, error, data, refetch } = useQuery(GET_TEXT_PROFILES, {
    variables: {
      includeDefault: false
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    console.log("Error", error);
    //return <Error errorCode={500} />;
  }

  if (!data) {
    console.log("Error, no data");
    //return (<Error errorCode={500} />);
  }

  const onDeleteButtonClick = (e: any, row: any) => {
    e.stopPropagation();
    //console.log(row);
    setSelectedProfileID(row.id);
    setDeleteProfile(true);
    //do whatever you want with the row
  };

  const handleDeleteProfileClickClose = () => {
    setDeleteProfile(false);
  };

  const handleDeleteProfileClick = () => {
    DeleteTextProfile({
      variables: {
        id: selectedProfileID
      }
    }).then(() => {
      setDeleteProfile(false);
      refetch()
    });
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Navn', flex: 1, filterable: false },
    { field: 'created', headerName: 'Oprettet', width: 120, align: 'left' },
    {
      field: 'actions', headerName: 'Slet', width: 50, align: 'left', renderCell: (params) => {
        return (
          <DeleteOutlinedIcon
            onClick={(e) => onDeleteButtonClick(e, params.row)}
          />

        );
      }
    }
  ];


  const rows = data.getTextProfiles.map((order: any) => {
    return {
      id: order.id,
      name: order.name,
      created: moment(order.createdAt).format("DD-MM-YYYY")
    }
  });

  const handleRowClicked = (row: any) => {
    navigate('/profile/' + row.id);
  }

  const handleCreateNewProfileClickOpen = () => {
    setOpenCreateNewProfile(true);
  };

  const handleCreateNewProfileClickClose = () => {
    setOpenCreateNewProfile(false);
  };

  const handleCreateNewProfileClicked = () => {
    CreateTextProfile({
      variables: {
        input: {
          name: newProfileName,
          language: "da"
        }
      }
    }).then(() => {
      handleCreateNewProfileClickClose();
      refetch()
    });
  }

  return (
    <>
      <div className="orderList MainArea">
        <header className="TopAreaMain">
          <Logo />
          <TopMenu selectedItem={TopMenuItem.Settings} view={viewType.EstateAgent} />
        </header>
        <Grid container spacing={0}>
          <Grid xs={12}>
            <div className="contentContainer">
              <div className="Heading">Tekst Profiler</div>
              <Grid container spacing={0}>
                <Grid xs={12} style={{ width: '100%', borderWidth: '0px', textAlign: 'end' }}>
                  <Button variant="outlined" color="primary" onClick={() => handleCreateNewProfileClickOpen()}>Ny profil</Button>
                </Grid>
                <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                  <DataGrid
                    style={{ borderWidth: '0px' }}
                    onRowClick={handleRowClicked}
                    rows={rows}
                    columns={columns}
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterValues: [''],
                        },
                      },
                    }}
                    autoHeight={true}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector

                    sx={{
                      // disable cell selection style
                      '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                      },
                      // pointer cursor on ALL rows
                      '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

      <Dialog
        fullWidth
        open={openCreateNewProfile}
        onClose={handleCreateNewProfileClickClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            handleCreateNewProfileClickClose();
          },
        }}
      >
        <DialogTitle>Opret ny tekst profile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du er ved at oprette en ny tekst profil.<br />Indtast profilens navn.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="profileName"
            label="Navn"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => setNewProfileName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateNewProfileClickClose}>Annuller</Button>
          <Button onClick={handleCreateNewProfileClicked} type="submit">Opret</Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openDeleteProfile}
        onClose={handleDeleteProfileClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Slet profil?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du er ved at slette en tekst profil. Er du sikker?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteProfileClickClose}>Fortryd</Button>
          <Button onClick={handleDeleteProfileClick} autoFocus>
            Slet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilList;
