import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./profilInfo.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent, CardHeader, Input } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from "moment";
import TextSection from "../../components/TextSection/TextSection";
import TextInstructions from "../../components/TextInstructions/TextInstructions";

const GET_PROFILE = gql`
  query getTextProfile($id: ID!) {
    getTextProfile(id: $id) {
      id
      name
      language
      createdAt
      updatedAt
      sections {
        id
        name
        externalRef
        textType
        textLength
        textLengthUnit
        sorting
        createdAt
        updatedAt
      }
    }
  }
`;

const EDIT_PROFILE = gql`
  mutation editTextProfile($input: EditTextProfileInput!) {
    editTextProfile(input: $input) {
      id
    }
  }
`;


const ProfilInfo: React.FC = () => {

  const { id } = useParams();
  //const [updateOrder] = useMutation(UPDATE_ORDER);

  const [ EditProfile ] = useMutation(EDIT_PROFILE);
  const { loading, error, data, refetch } = useQuery(GET_PROFILE, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }

  const handleFieldChange = (field: string, value: any) => {

    let input = {
      id: data.getTextProfile.id,
    } as any;
    input[field] = value;

    EditProfile({
      variables: {
        input: input
      }
    }).then(() => {
      
      refetch();
    }); 
  }

  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.Settings} view={viewType.EstateAgent} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Profil indstillinger</div>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Generelt'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Navn:</div>
                          <div className="itemData">
                            <Input fullWidth type="text" defaultValue={data.getTextProfile.name} onBlur={(event) => handleFieldChange('name', event.target.value)} />
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Sprog:</div>
                          <div className="itemData">

                            <Select
                              size="small"
                              id="language"
                              defaultValue={data.getTextProfile.language}
                              fullWidth
                              variant="outlined"
                              onChange={(event) => handleFieldChange('language', event.target.value)}
                            >
                              <MenuItem value={'da'}>Dansk</MenuItem>
                              <MenuItem value={'en'}>Engelsk</MenuItem>
                              <MenuItem value={'de'}>Tysk</MenuItem>
                              <MenuItem value={'it'}>Italiensk</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <br />
                        <div className="item">
                          <div className="itemLabel">Oprettet d.:</div>
                          <div className="itemData">{moment(data.getTextProfile.createdAt).format("DD-MM-YYYY")} {String(new Date(data.getTextProfile.createdAt).getHours()).padStart(2, '0') + ":" + String(new Date(data.getTextProfile.createdAt).getMinutes()).padStart(2, '0')}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Ændret d.:</div>
                          <div className="itemData">{moment(data.getTextProfile.updatedAt).format("DD-MM-YYYY")} {String(new Date(data.getTextProfile.updatedAt).getHours()).padStart(2, '0') + ":" + String(new Date(data.getTextProfile.updatedAt).getMinutes()).padStart(2, '0')}</div>
                        </div>
                      </div>

                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Tekster instrukser'} subheader={'Opret/redigere instrukser til teksterne'} />
                    <CardContent>
                      <TextInstructions textProfileId={data.getTextProfile.id} />

                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Tekster'} subheader={'Opret/redigere de tekster som skal genereres'} />
                    <CardContent>
                      <TextSection textProfileId={data.getTextProfile.id} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>


    </div>
  );
};

export default ProfilInfo;
